import { createStore } from "vuex";
import { auth } from "./modules/auth.module";
import { ProjectMetaModule, ProjectStoreModule } from "@/models/project";
import { NetworkStoreModule, NetworkModalStoreModule, SpliceClosure } from "@/models/network";
import {
  DatapoolStoreModule,
  DatapoolModalStoreModule,
} from "@/models/datapool";
import { CadastralStoreModule } from "@/models/cadastral";
import { JobsStoreModule } from "@/models/jobs";
import { RouteStoreModule } from "@/models/routes";
import { MaterialStoreModule } from "@/models/materials";
import { DashboardStoreModule } from "@/models/dashboard";
import { AccountStoreModule } from "@/models/account";
import { ProviderStoreModule } from "@/models/provider";
import { ConnectedRoute } from "@/models/routes";
import { NewJobsStoreModule } from "@/models/jobs";
import {
  NetworkNode,
  HouseBox,
  Pipe,
  Tube,
  TubeConnector,
  BunchConnection,
  Cable,
  SpliceTray,
  Cabinet,
  PatchField,
  PatchFieldPort,
  PatchFieldPortConnection,
} from "@/models/network";

const UIStoreModule = {
  namespaced: true,
  state() {
    return {
      sideNav: {
        navigation: [],
      },
      mapInstance: null,
      mapFocusPosition: null,
    };
  },
  mutations: {
    RESET_SIDENAV(state) {
      state.sideNav = {
        navigation: [],
      };
    },
    SET_SIDENAV(state, payload) {
      state.sideNav = payload;
    },
    SET_MAP_INSTANCE(state, payload) {
      state.mapInstance = payload;
    },
    SET_MAP_FOCUS_POSITION(state, payload) {
      state.mapFocusPosition = payload;
    },
    RESET_MAP_FOCUS_POSITION(state) {
      state.mapFocusPosition = null;
    },
  },
  actions: {
    setSideNav: async ({ commit }, payload) => {
      try {
        commit("SET_SIDENAV", payload);
      } catch (err) {
        console.log(err);
      }
    },
    resetSideNav: async ({ commit }) => {
      try {
        commit("RESET_SIDENAV");
      } catch (err) {
        console.log(err);
      }
    },
    setMapInstance: async ({ commit }, payload) => {
      try {
        commit("SET_MAP_INSTANCE", payload);
      } catch (err) {
        console.log(err);
      }
    },
    setMapFocusPosition: async ({ commit }, payload) => {
      try {
        commit("SET_MAP_FOCUS_POSITION", payload);
      } catch (err) {
        console.log(err);
      }
    },
    resetMapFocusPosition: async ({ commit }) => {
      try {
        commit("RESET_MAP_FOCUS_POSITION");
      } catch (err) {
        console.log(err);
      }
    },
  },
  getters: {
    sideNav(state) {
      return state.sideNav;
    },
    mapInstance(state) {
      return state.mapInstance;
    },
    mapFocusPosition(state) {
      return state.mapFocusPosition;
    },
  },
};

// define objects based on module namespaces from vuex-store
// like network, routes, ...
const UPDATESTATE_MAP = {
  network: {
    pipes: Pipe,
    tubes: Tube,
    cables: Cable,
    spliceTrays: SpliceTray,
    spliceClosures: SpliceClosure,
    cabinets: Cabinet,
    nodes: NetworkNode,
    nodesHouseBox: HouseBox,
    patchFields: PatchField,
    patchFieldPorts: PatchFieldPort,
    patchFieldPortConnections: PatchFieldPortConnection,
    tubeConnectors: TubeConnector,
    bunchConnections: BunchConnection,
  },
  routes: {
    connectedRoutes: ConnectedRoute,
  },
};

export const updateStateFromAPI = (response) => {
  let data = response.data || response;
  for (const moduleNamespace in UPDATESTATE_MAP) {
    for (const type in UPDATESTATE_MAP[moduleNamespace]) {
      if (data[type.toLowerCase()]) {
        data[type.toLowerCase()].forEach((d) => {
          let obj = UPDATESTATE_MAP[moduleNamespace][type].from_api(d);
          updateInState(obj, type, moduleNamespace);
        });
      }
      if (data["DELETED"] && data["DELETED"][type.toLowerCase()]) {
        data["DELETED"][type.toLowerCase()].forEach((d) => {
          let obj = UPDATESTATE_MAP[moduleNamespace][type].from_api(d);
          removeFromState(obj, type, moduleNamespace);
        });
      }
    }
  }
};

export const updateInState = (obj, key, moduleNamespace) => {
  let cname = key.charAt(0).toUpperCase() + key.slice(1);
  store.commit(`${moduleNamespace}/add${cname}`, { object: obj });
};

export const removeFromState = (obj, key, moduleNamespace) => {
  let cname = key.charAt(0).toUpperCase() + key.slice(1);
  let sname = cname.replace(/s$/, "");
  store.commit(`${moduleNamespace}/remove${sname}`, { object: obj });
};

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    ui: UIStoreModule,
    projects: ProjectStoreModule,
    projectsmeta: ProjectMetaModule,
    network: NetworkStoreModule,
    networkModal: NetworkModalStoreModule,
    materials: MaterialStoreModule,
    datapool: DatapoolStoreModule,
    datapoolModal: DatapoolModalStoreModule,
    cadastral: CadastralStoreModule,
    jobs: NewJobsStoreModule,
    routes: RouteStoreModule,
    dashboard: DashboardStoreModule,
    accounts: AccountStoreModule,
    providers: ProviderStoreModule,
  },
});
export default store;
