import config from "@/app.config";
import store from "@/store/index.js";
import { LWLModel } from "./base.js";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
} from "@/components/base/ModuleGenerator";

class Material extends LWLModel {
  static ENDPOINT = null;

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.MATERIALS, param);
  }
}

export class Manufacturer extends Material {
  static ENDPOINT = "manufacturer";
}
export class PipeType extends Material {
  static ENDPOINT = "pipetype";

  getManufacturer() {
    store.dispatch("materials/fetchAllManufacturers");
    return store.getters["materials/manufacturersById"](this.manufacturer);
  }
}
export class CableType extends Material {
  static ENDPOINT = "cabletype";
}
export class SpliceTrayType extends Material {
  static ENDPOINT = "splicetraytype";
}
export class SpliceClosueType extends Material {
  static ENDPOINT = "spliceclosuretype";
}
export class CabinetType extends Material {
  static ENDPOINT = "cabinettype";
}
export class PatchFieldType extends Material {
  static ENDPOINT = "patchfieldtype";
}
export class HouseBoxType extends Material {
  static ENDPOINT = "houseboxtype";
}
export class DistributorType extends Material {
  static ENDPOINT = "distributortype";
}
export class WellType extends Material {
  static ENDPOINT = "welltype";
}
export class CenterType extends Material {
  static ENDPOINT = "centertype";
}
export class TechnicalTransferPointType extends Material {
  static ENDPOINT = "technicaltransferpointtype";
}

export const MaterialStoreModule = new SimpleModuleGenerator(
  {
    manufacturers: Manufacturer,
    cableTypes: CableType,
    pipeTypes: PipeType,
    spliceTrayTypes: SpliceTrayType,
    spliceClosureTypes: SpliceClosueType,
    cabinetTypes: CabinetType,
    patchFieldTypes: PatchFieldType,
    houseBoxTypes: HouseBoxType,
    distributorTypes: DistributorType,
    wellTypes: WellType,
    centerTypes: CenterType,
    technicalTransferPointTypes: TechnicalTransferPointType,
  },
  {
    state: {},
    mutations: {},
    actions: {},
    getters: {},
  }
).module();
