import { LWLModel } from "./base.js";
import config from "@/app.config";
import { markRaw, createApp, h } from "vue";
import { NAvatar as CoreAvatar } from "naive-ui";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
} from "@/components/base/ModuleGenerator";

const Avatar = markRaw(CoreAvatar);

export class Account extends LWLModel {
  constructor(obj) {
    if (new.target === Account) {
      throw new Error(
        `Abstract class ${new.target.name} can't be instantiated.`
      );
    } else {
      super(obj);
    }
  }

  prepareForApi() {
    let formData = new FormData();

    formData.append("first_name", this.first_name);
    formData.append("last_name", this.last_name);
    formData.append("phone", this.phone);
    formData.append("email", this.email);
    formData.append("gender", this.gender);
    formData.append("address", JSON.stringify(this.address));

    if (this.birthday == null) formData.append("birthday", "");
    else formData.append("birthday", this.birthday);

    return formData;
  }

  static getGenderOptions() {
    return [
      {
        label: "Mann",
        value: 1,
      },
      {
        label: "Frau",
        value: 2,
      },
      {
        label: "Divers",
        value: 3,
      },
    ];
  }

  get name() {
    let name = [];
    if (this.first_name && this.last_name) {
      name.push(this.first_name + " " + this.last_name);
    }
    if (this.address != null && this.address.company != null)
      name.push(this.address.company);
    return name.join(" - ");
  }

  get fullName() {
    if (this && this.first_name && this.last_name)
      return `${this.first_name} ${this.last_name}`;
    if (this && this.first_name) return `${this.first_name}`;
    return "";
  }

  get cropFirstName() {
    if (this && this.first_name && this.last_name)
      return `${this.first_name.substring(0, 1)}. ${this.last_name}`;
    if (this && this.last_name) return `${this.last_name}`;
    return "";
  }

  get cropLastName() {
    if (this && this.first_name && this.last_name)
      return `${this.first_name} ${this.last_name.substring(0, 1)}.`;
    if (this && this.first_name) return `${this.first_name}`;
    return "";
  }

  get cropName() {
    if (this && this.first_name && this.last_name)
      return `${this.first_name.substring(0, 1)}${this.last_name.substring(
        0,
        1
      )}`;
    if (this && this.first_name) return `${this.first_name.substring(0, 1)}`;
    return "";
  }

  getStreetAndNumber() {
    let content = [];
    if (this.address?.street) content.push(this.address.street);
    if (this.address?.number) content.push(this.address.number);
    return content.join(" ");
  }
  getZipCodeAndCity() {
    let content = [];
    if (this.address?.zip) content.push(this.address.zip);
    if (this.address?.city) content.push(this.address.city);
    return content.join(" ");
  }

  getFullAddress() {
    let content = [];
    if (this.getStreetAndNumber()) content.push(this.getStreetAndNumber());
    if (this.getZipCodeAndCity()) content.push(this.getZipCodeAndCity());
    return content.join(", ");
  }

  getFullInformation() {
    let content = [];
    if (this.getFullAddress()) content.push(this.getFullAddress());
    if (this.email) content.push(this.email);
    return content.join(", ");
  }
}

export class Client extends Account {
  static ENDPOINT = "client";
  static VERBOSE_NAME = "Kunde";
  static VERBOSE_NAME_PLURAL = "Kunden";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }

  get name() {
    let name = super.name;
    if (name !== "") name = [name];
    else name = [];
    if (this.email != null) name.push(this.email);
    return name.join(" - ");
  }

  getFullClientMetaData() {
    return {
      first_name: {
        key: "first_name",
        label: "Vorname",
        value: this.first_name,
      },
      last_name: {
        key: "last_name",
        label: "Nachname",
        value: this.last_name,
      },
      company: {
        key: "company",
        label: "Firma",
        value: this.address?.company,
      },
      street: {
        key: "street",
        label: "Straße",
        value: this.address?.street,
      },
      number: {
        key: "number",
        label: "Nummer",
        value: this.address?.number,
      },
      zip: {
        key: "zip",
        label: "PLZ",
        value: this.address?.zip,
      },
      city: {
        key: "city",
        label: "Ort",
        value: this.address?.city,
      },
      phone: {
        key: "phone",
        label: "Telefonnummer",
        value: this.phone,
      },
      email: {
        key: "email",
        label: "E-Mail",
        value: this.email,
      },
    };
  }
}

export class User extends Account {
  static ENDPOINT = "user";
  static VERBOSE_NAME = "Benutzer";
  static VERBOSE_NAME_PLURAL = "Benutzer";

  constructor(obj) {
    super(obj);
  }

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.ROOT, param);
  }

  getUsername() {
    if (this) return this.username;
    return "";
  }

  get name() {
    let name = super.name;
    if (name !== "") name = [name];
    else name = [];
    if (this.username && this.username.includes("@")) {
      name.push(this.username);
    }
    if (this.username && name.length == 0) {
      name.push(this.username);
    }
    return name.join(" - ");
  }

  get fullName() {
    const fullName = super.fullName;
    if (fullName) return fullName;
    if (this) return this.username;
    return "";
  }

  get cropLastName() {
    const name = super.cropLastName;
    if (name) return name;
    if (this) return this.username;
    return "";
  }

  get cropFirstName() {
    const name = super.cropFirstName;
    if (name) return name;
    if (this) return this.username;
    return "";
  }

  get cropName() {
    const name = super.cropLastName;
    if (name) return name;
    if (this) return this.username?.substring(0, 1);
    return "";
  }

  prepareImageForApi(data) {
    let formData = new FormData();
    if (data.file && data.file.file) {
      let file = data.file.file;
      formData.append("avatar", file);
    }
    return formData;
  }

  getAvatarList() {
    if (!this.image) {
      return [];
    }
    return [
      {
        name: this.username,
        url: this.image,
      },
    ];
  }

  getRenderAvatar(size = 50, user = this) {
    if (user.image) {
      return h(Avatar, {
        size: size,
        src: user.image,
        round: true,
        "object-fit": "cover",
      });
    } else {
      return h(
        Avatar,
        {
          size: size,
          round: true,
          "object-fit": "cover",
          style: `font-size: ${size * 0.48}px; text-transform: uppercase;`,
        },
        {
          default: () => {
            return user.cropName;
          },
        }
      );
    }
  }

  getRenderedAvatar(size = 50, user = this) {
    let Icon = createApp({
      render() {
        return user.getRenderAvatar(size, user);
      },
    });

    let instance = Icon.mount(document.createElement("div"));
    return instance.$el.outerHTML;
  }

  getImageUploadUrl() {
    return `${config.endpoints.USER}${this.id}/avatar/`;
  }

  get image() {
    return this?.avatar?.md ?? "";
  }

  get userRoll() {
    if (!this) return "";
    if (this.groups == 0) return "";
    return this.groups[0].name;
  }

  hasPermission(codename) {
    if (this.is_superuser) {
      return true;
    } else {
      return this.permissions.findIndex((p) => p === codename) > -1;
    }
  }
}

export const AccountStoreModule = new SimpleModuleGenerator({
  clients: Client,
  users: User,
}).module();
