<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 46 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2507_154)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 0C4.89543 0 4 0.89543 4 2V36C4 37.1046 4.89543 38 6 38H16.2941H17.5373L22.2744 42.4925C22.6751 42.8725 23.3249 42.8725 23.7256 42.4925L28.4627 38H29.7059H40C41.1046 38 42 37.1046 42 36V2C42 0.895431 41.1046 0 40 0H6Z"
        :fill="backgroundColor"
      />
      <path
        d="M29.6667 17.6667V7H16.3333V12.3333H11V31H21.6667V25.6667H24.3333V31H35V17.6667H29.6667ZM16.3333 28.3333H13.6667V25.6667H16.3333V28.3333ZM16.3333 23H13.6667V20.3333H16.3333V23ZM16.3333 17.6667H13.6667V15H16.3333V17.6667ZM21.6667 23H19V20.3333H21.6667V23ZM21.6667 17.6667H19V15H21.6667V17.6667ZM21.6667 12.3333H19V9.66667H21.6667V12.3333ZM27 23H24.3333V20.3333H27V23ZM27 17.6667H24.3333V15H27V17.6667ZM27 12.3333H24.3333V9.66667H27V12.3333ZM32.3333 28.3333H29.6667V25.6667H32.3333V28.3333ZM32.3333 23H29.6667V20.3333H32.3333V23Z"
        :fill="strokeColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2507_154"
        x="0"
        y="0"
        width="46"
        height="50.7776"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2507_154"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2507_154"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "CenterNodeSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 46,
      height: 51,
    };
  },
};
</script>
