const SVGMixin = {
  data() {
    return {
      width: 24,
      height: 24,
    };
  },
  props: {
    backgroundColor: {
      type: String,
      default: "#217011",
    },
    strokeColor: {
      type: String,
      default: "#E9EFF4",
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    scaledWidth() {
      return this.width * this.scale;
    },
    scaledHeight() {
      return this.height * this.scale;
    },
  },
};

export default SVGMixin;