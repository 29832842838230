import axios from "axios";
// @see: https://stackoverflow.com/questions/50461746/axios-how-to-cancel-request-inside-request-interceptor-properly

const currentExecutingRequests = {};
export function setConcurrentRequestsInterceptors(axios_instance) {
  axios_instance.interceptors.request.use(
    (req) => {
      let originalRequest = req;

      if (currentExecutingRequests[req.url]) {
        // console.info(
        //   "cancel concurrent request",
        //   req.url,
        //   currentExecutingRequests
        // );
        const source = currentExecutingRequests[req.url];
        delete currentExecutingRequests[req.url];
        source.cancel();
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      originalRequest.cancelToken = source.token;
      currentExecutingRequests[req.url] = source;

      // here you could add the authorization header to the request

      return originalRequest;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios_instance.interceptors.response.use(
    (response) => {
      if (currentExecutingRequests[response.request.responseURL]) {
        // here you clean the request
        delete currentExecutingRequests[response.request.responseURL];
      }
      return response;
    },
    (error) => {
      const { config, response } = error;
      const originalRequest = config;

      if (axios.isCancel(error)) {
        // here you check if this is a cancelled request to drop it silently (without error)
        return new Promise(() => {});
      }

      if (originalRequest && currentExecutingRequests[originalRequest.url]) {
        // here you clean the request
        delete currentExecutingRequests[originalRequest.url];
      }

      // here you could check expired token and refresh it if necessary

      return Promise.reject(error);
    }
  );
}
