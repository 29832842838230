<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2507_99)">
      <path
        d="M4 2.5L4 22.5C4 23.8807 5.08213 25 6.41701 25H26.583C27.9179 25 29 23.8807 29 22.5V2.5C29 1.11929 27.9179 0 26.583 0H6.41701C5.08213 0 4 1.11929 4 2.5Z"
        :fill="backgroundColor"
      />
      <path d="M4 12.5H29" :stroke="strokeColor" stroke-width="2.5" />
      <circle
        cx="16.5"
        cy="12.5"
        r="9"
        :fill="backgroundColor"
        :stroke="strokeColor"
      />
      <path
        d="M16.5 23.4736L23.5261 23.4736L17.1662 29.8335C16.7982 30.2014 16.2016 30.2014 15.8337 29.8334L9.4738 23.4736L16.5 23.4736Z"
        :fill="backgroundColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2507_99"
        x="0.231244"
        y="0"
        width="32.5375"
        height="37.6469"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.76876" />
        <feGaussianBlur stdDeviation="1.88438" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2507_99"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2507_99"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "BorderNodeSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 33,
      height: 38,
    };
  },
};
</script>
