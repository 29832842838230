import AuthService from "@/services/auth.service";
import { User } from "@/models/account";

const token = AuthService.getToken();
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: { loggedIn: false }, token: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (token) => {
          commit("loginSuccess", token);
          return Promise.resolve(token);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    async whoami({ commit }) {
      return AuthService.whoami().then((response) => {
        let user = User.fromAPI(response.data);
        commit("whoami", user);
        return user;
      });
    },
  },
  mutations: {
    loginSuccess(state, token) {
      state.status.loggedIn = true;
      state.token = token;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.token = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.token = null;
      state.user = null;
    },
    whoami(state, data) {
      state.user = data;
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    hasPermissionsLoaded(state) {
      return state.user != null;
    },
    hasPermission: (state) => (codename) => {
      if (!state.user) {
        return false;
      } else {
        return state.user.hasPermission(codename);
      }
    },
  },
};
