<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 46 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2468_5)">
      <path
        d="M4 2.5L4 22.5C4 23.8807 5.07623 25 6.40382 25L39.3888 25C40.7164 25 41.7926 23.8807 41.7926 22.5V2.5C41.7926 1.11929 40.7164 0 39.3888 0L6.40382 0C5.07623 0 4 1.11929 4 2.5Z"
        :fill="backgroundColor"
      />
      <path
        d="M6.5541 20.8334L6.5541 4.16675C6.5541 3.27633 7.27592 2.55451 8.16632 2.55451L37.6257 2.55451C38.5161 2.55451 39.2379 3.27633 39.2379 4.16675V20.8334C39.2379 21.7238 38.5161 22.4457 37.6257 22.4457L8.16632 22.4457C7.27592 22.4457 6.5541 21.7238 6.5541 20.8334Z"
        :stroke="strokeColor"
        stroke-width="0.942189"
      />
      <path
        d="M6.73127 11.4808L9.41647 8.79559L8.07387 7.453"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M6.73127 22.2217L9.41647 19.5366L8.07387 18.194"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M12.1018 6.10989L9.41658 3.42471L10.2499 2.59242"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M12.1014 11.4808L14.7866 8.79559L13.444 7.453"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M12.1017 16.8519L9.41654 14.1667L10.7591 12.8241"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M12.1014 22.2217L14.7866 19.5366L13.444 18.194"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M17.4716 6.1114L14.7864 3.42622L15.4349 2.77734"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M17.4715 11.4808L20.1567 8.79559L18.8141 7.453"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M22.8422 6.11092L20.157 3.42574L20.8055 2.77821"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M22.8426 11.4808L25.5278 8.79559L24.1852 7.453"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M28.2122 6.11085L25.527 3.42566L26.2214 2.7319"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M28.2127 11.4808L30.8979 8.79559L29.5553 7.453"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M17.4719 16.8519L14.7867 14.1667L16.1293 12.8241"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M17.4715 22.2217L20.1567 19.5366L18.8141 18.194"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M22.8429 16.8519L20.1578 14.1667L21.5004 12.8241"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M22.8426 22.2217L25.5278 19.5366L24.1852 18.194"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M28.2131 16.8519L25.5279 14.1667L26.8705 12.8241"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M28.2127 22.2217L30.8979 19.5366L29.5553 18.194"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M33.5838 6.11003L30.8986 3.42485L31.778 2.54611"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M33.5838 11.4808L36.269 8.79559L34.9264 7.453"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M33.5822 16.8514L30.897 14.1662L32.2396 12.8236"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M33.5828 22.2213L36.268 19.5361L34.9254 18.1935"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M38.954 6.11191L36.2688 3.42672L36.917 2.77783"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M38.9533 16.8519L36.2681 14.1667L37.6107 12.8241"
        :stroke="strokeColor"
        stroke-width="0.7"
      />
      <path
        d="M23.5263 24.4737L30.0523 24.4737L24.1925 30.3334C23.8246 30.7013 23.228 30.7013 22.8601 30.3334L17.0003 24.4736L23.5263 24.4737Z"
        :fill="backgroundColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2468_5"
        x="0.231244"
        y="0"
        width="45.3305"
        height="38.1469"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.76876" />
        <feGaussianBlur stdDeviation="1.88438" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2468_5"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2468_5"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "WellUFSNodeSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 46,
      height: 39,
    };
  },
};
</script>
