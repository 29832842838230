// import Vue from 'vue'
import axios from "axios";
import { setConcurrentRequestsInterceptors } from "@/utils/axios.interceptors";
import Cookies from "js-cookie";

import jwt_decode from "jwt-decode";
import config from "@/app.config";

// use this instance for get requests (with interseptors)
// Create `axios-cache-adapter` instance
// import { setupCache } from 'axios-cache-adapter'
const read_api = axios.create({
  // adapter: setupCache({ maxAge: 10 * 1000 }).adapter
});

// use this instance for post, patch, put and delete requests (no interseptors for this instance)
const write_api = axios.create({});

export const abortController = new AbortController();
const signal = abortController.signal;

setConcurrentRequestsInterceptors(read_api);

const COOKIE_NAME = "lwl-jwt";

class AuthService {
  async login(user) {
    return axios
      .post(config.endpoints.AUTH + "obtain/", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access) {
          localStorage.setItem("lwl-token", response.data.access);
          localStorage.setItem("lwl-refresh-token", response.data.refresh);
          localStorage.setItem("username", user.username);
          Cookies.set(COOKIE_NAME, response.data.access, { expires: 31 });
          return response.data.access;
        }
      });
  }

  refresh() {
    return axios
      .post(config.endpoints.AUTH + "refresh/", {
        token: this.getRefreshToken(),
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("lwl-token", response.data.access);
          localStorage.setItem("lwl-refreshToken", response.data.refresh);
          Cookies.set(COOKIE_NAME, response.data.access, { expires: 31 });
          return response.data.access;
        }
      });
  }

  logout() {
    localStorage.removeItem("lwl-token");
    localStorage.removeItem("username");
    Cookies.remove(COOKIE_NAME);
  }

  whoami() {
    return this.get(config.endpoints.WHOAMI);
  }

  getAuthHeader() {
    const token = this.getToken();
    if (token) {
      return {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    } else {
      return {};
    }
  }

  getToken() {
    return localStorage.getItem("lwl-token");
  }

  getRefreshToken() {
    return localStorage.getItem("lwl-refresh-token");
  }

  isLoggedIn() {
    let token = localStorage.getItem("lwl-token");
    if (token) {
      let decoded = jwt_decode(token);
      return new Date(decoded.exp * 1000) > new Date();
    }
    return false;
  }

  isDueForRefresh() {
    let token = this.getToken()
    let decoded = jwt_decode(token);
    const expiresInMs = new Date(decoded.exp * 1000) - new Date();
    const nextThirtyMinutes = 30 * 60 * 1000;
    return expiresInMs < nextThirtyMinutes;
  }

  get(url) {
    return read_api.get(url, { headers: this.getAuthHeader(), signal });
  }

  getWithExternalSignal(url, signal) {
    return read_api.get(url, { headers: this.getAuthHeader(), signal });
  }

  put(url, data) {
    return write_api.put(url, data, {
      headers: {
        Authorization: "Bearer " + this.getToken(),
        "Content-Type": "multipart/form-data",
      },
      signal,
    });
  }

  post(url, data) {
    return write_api.post(url, data, { headers: this.getAuthHeader(), signal });
  }

  patch(url, data) {
    return write_api.patch(url, data, {
      headers: this.getAuthHeader(),
      signal,
    });
  }

  delete(url) {
    return write_api.delete(url, { headers: this.getAuthHeader(), signal });
  }
}

export default new AuthService();
