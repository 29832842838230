<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1914_15)">
      <circle cx="10.5" cy="7.5" r="7.5" :fill="backgroundColor" />
      <path
        d="M10.5 2.6001L15 10.6001H10.6514H6L10.5 2.6001Z"
        :fill="strokeColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1914_15"
        x="0.6"
        y="0"
        width="21"
        height="20"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.4" />
        <feGaussianBlur stdDeviation="1.2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1914_15"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1914_15"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "VNodeSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 25,
      height: 24,
    };
  },
};
</script>
