<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="16.5" cy="12.5" r="12.5" :fill="backgroundColor" />
    </g>

    <template v-if="isConnectionStatusInterested()">
      <path
        d="M16.9662 4.91503L18.91 8.85253L23.2562 9.48503C23.3522 9.49887 23.4424 9.53932 23.5166 9.60179C23.5908 9.66426 23.646 9.74626 23.676 9.83851C23.706 9.93075 23.7096 10.0295 23.6863 10.1237C23.663 10.2179 23.6139 10.3036 23.5444 10.3713L20.3987 13.4338L21.1412 17.7625C21.1576 17.8581 21.1469 17.9564 21.1104 18.0463C21.0738 18.1361 21.0128 18.214 20.9344 18.271C20.8559 18.328 20.763 18.3619 20.6663 18.3689C20.5695 18.376 20.4727 18.3558 20.3869 18.3106L16.5 16.27L12.6125 18.3125C12.5265 18.3574 12.4297 18.3775 12.333 18.3703C12.2363 18.3632 12.1435 18.3292 12.0651 18.2721C11.9867 18.2151 11.9257 18.1373 11.8892 18.0474C11.8526 17.9576 11.8418 17.8594 11.8581 17.7638L12.6006 13.4375L9.45498 10.375C9.38547 10.3074 9.3363 10.2216 9.31302 10.1275C9.28975 10.0333 9.29331 9.9345 9.3233 9.84226C9.35329 9.75001 9.40851 9.66801 9.4827 9.60554C9.5569 9.54307 9.6471 9.50262 9.7431 9.48878L14.0894 8.85315L16.0331 4.91565C16.0761 4.82867 16.1425 4.75543 16.225 4.7042C16.3074 4.65298 16.4024 4.6258 16.4995 4.62573C16.5965 4.62567 16.6916 4.65272 16.7741 4.70384C16.8566 4.75495 16.9231 4.8281 16.9662 4.91503Z"
        :fill="strokeColor"
      />
    </template>

    <template v-else-if="technicalUsage === 'tr'">
      <path
        d="M8 8H15.7"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8496 17.1V8"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2197 17.1V8H21.3697C22.0195 8 22.6427 8.25812 23.1021 8.71759C23.5616 9.17705 23.8197 9.80022 23.8197 10.45C23.8197 11.0998 23.5616 11.7229 23.1021 12.1824C22.6427 12.6419 22.0195 12.9 21.3697 12.9H18.2197"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.1701 17.0999L21.3701 12.8999"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'str'">
      <path
        d="M7.5 16.32C8.14822 16.7193 8.86413 16.9348 9.59265 16.95C10.9503 16.95 12.225 16.383 12.225 14.43C12.225 12.8008 10.8857 12.225 9.6 11.91C8.55 11.6523 7.5 10.965 7.5 9.39C7.5 8.03991 8.63715 7.5 9.59265 7.5C10.3269 7.50581 11.0493 7.72179 11.7 8.13"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3633 7.67505H19.6633"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5137 16.775V7.67505"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.4131 16.775V7.67505H23.1631C23.8129 7.67505 24.436 7.93317 24.8955 8.39264C25.355 8.8521 25.6131 9.47527 25.6131 10.125C25.6131 10.7748 25.355 11.398 24.8955 11.8575C24.436 12.3169 23.8129 12.575 23.1631 12.575H21.4131"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.9631 16.775L23.1631 12.575"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'sst'">
      <path
        d="M7.5 16.82C8.14822 17.2193 8.86413 17.4348 9.59265 17.45C10.9503 17.45 12.225 16.883 12.225 14.93C12.225 13.3008 10.8857 12.725 9.6 12.41C8.55 12.1523 7.5 11.465 7.5 9.89C7.5 8.53991 8.63715 8 9.59265 8C10.3269 8.00581 11.0493 8.22179 11.7 8.63"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0615 16.82C14.7097 17.2193 15.4257 17.4348 16.1542 17.45C17.5118 17.45 18.7865 16.883 18.7865 14.93C18.7865 13.3008 17.4472 12.725 16.1615 12.41C15.1115 12.1523 14.0615 11.465 14.0615 9.89C14.0615 8.53991 15.1987 8 16.1542 8C16.8884 8.00581 17.6108 8.22179 18.2615 8.63"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3623 8.17505H26.6623"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.5127 17.275V8.17505"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'sh'">
      <path
        d="M9 16.6C9.80255 17.012 10.6889 17.2343 11.5909 17.25C13.2718 17.25 14.85 16.665 14.85 14.65C14.85 12.9691 13.1918 12.375 11.6 12.05C10.3 11.7841 9 11.075 9 9.45C9 8.05705 10.4079 7.5 11.5909 7.5C12.4999 7.506 13.3944 7.72883 14.2 8.15"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5752 16.925V7.82495"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.875 16.925V7.82495"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5752 12.0249H23.8752"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'uw'">
      <path
        d="M17.1699 8.33521L19.1236 16.8011L21.4029 8.33521L23.6822 16.8011L25.6358 8.33521"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.63672 8.32593V13.9422C8.63672 14.7621 8.96243 15.5485 9.54221 16.1283C10.122 16.708 10.9083 17.0337 11.7282 17.0337C12.5482 17.0337 13.3345 16.708 13.9143 16.1283C14.4941 15.5485 14.8198 14.7621 14.8198 13.9422V8.32593"
        :stroke="strokeColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'mpr'">
      <path
        d="M7.5 16.8741V8.50024H7.82207L10.3394 13.7745L12.8567 8.50024H13.1788V16.8741"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7051 16.8739V8.5H17.5188C18.1167 8.5 18.6902 8.73753 19.113 9.16033C19.5357 9.58313 19.7733 10.1566 19.7733 10.7545C19.7733 11.3524 19.5357 11.9259 19.113 12.3487C18.6902 12.7715 18.1167 13.009 17.5188 13.009H15.7051"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0098 16.8739V8.5H23.8235C24.4214 8.5 24.9949 8.73753 25.4176 9.16033C25.8404 9.58313 26.078 10.1566 26.078 10.7545C26.078 11.3524 25.8404 11.9259 25.4176 12.3487C24.9949 12.7715 24.4214 13.009 23.8235 13.009H22.0098"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.3998 16.8739L23.8232 13.009"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'gss'">
      <path
        d="M14.5957 16.7882C15.265 17.2005 16.0041 17.423 16.7563 17.4387C18.158 17.4387 19.4741 16.8533 19.4741 14.8369C19.4741 13.1548 18.0913 12.5603 16.7639 12.2351C15.6798 11.969 14.5957 11.2594 14.5957 9.63325C14.5957 8.23932 15.7698 7.68188 16.7563 7.68188C17.5143 7.68789 18.2602 7.91088 18.932 8.33234"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3711 16.7885C22.0403 17.2007 22.7795 17.4233 23.5317 17.4389C24.9334 17.4389 26.2494 16.8535 26.2494 14.8371C26.2494 13.155 24.8667 12.5605 23.5393 12.2353C22.4552 11.9693 21.3711 11.2596 21.3711 9.63349C21.3711 8.23957 22.5452 7.68213 23.5317 7.68213C24.2897 7.68813 25.0356 7.91112 25.7074 8.33258"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1913 8.02455C11.4824 7.6626 10.6946 7.48278 9.89885 7.5013C6.70082 7.5013 7.00798 10.1515 7.00798 12.5604C7.00798 14.9692 6.70082 17.6195 9.89885 17.6195C10.896 17.6765 11.3441 17.6195 12.1913 17.1656V13.0461H10.0232"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'gra'">
      <path
        d="M14.2842 17.059V8.00024H16.0262C16.673 8.00024 17.2934 8.2572 17.7507 8.71458C18.2081 9.17196 18.4651 9.79231 18.4651 10.4391C18.4651 11.086 18.2081 11.7063 17.7507 12.1637C17.2934 12.6211 16.673 12.878 16.0262 12.878H14.2842"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8136 17.0589L16.0264 12.8779"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6477 8.46963C11.0131 8.14558 10.3077 7.98458 9.59531 8.00116C6.73215 8.00116 7.00714 10.3739 7.00714 12.5305C7.00714 14.6872 6.73215 17.0599 9.59531 17.0599C10.4881 17.111 10.8892 17.0599 11.6477 16.6536V12.9654H9.7066"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.4121 16.9982L23.162 8.06079H23.8495L26.5994 16.9982"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.4697 14.2483H25.5424"
        :stroke="strokeColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else>
      <path
        d="M14.8 19V13.7059H18.2V19H22.45V11.9412H25L16.5 4L8 11.9412H10.55V19H14.8Z"
        :fill="strokeColor"
      />
    </template>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="33"
        height="33"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import { HouseBox } from "@/models/network";
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "HouseBoxSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 30,
      height: 30,
    };
  },
  props: {
    connectionStatus: {
      type: Number,
      default: 0,
    },
    technicalUsage: {
      type: String,
      default: "housebox",
      required: false,
    },
  },
  methods: {
    isConnectionStatusInterested() {
      return this.connectionStatus === HouseBox.CONNECTION_STATUS_INTERESTED;
    },
  },
};
</script>
