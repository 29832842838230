<template>
  <n-theme-editor v-if="!isProduction" style="z-index: 100000">
    <n-config-provider
      :locale="deDE"
      :date-locale="dateDeDE"
      :theme-overrides="themeOverrides"
    >
      <div id="app">
        <router-view></router-view>
      </div>
    </n-config-provider>
  </n-theme-editor>
  <n-config-provider
    :locale="deDE"
    :date-locale="dateDeDE"
    :theme-overrides="themeOverrides"
    v-else
  >
    <div id="app">
      <router-view></router-view>
    </div>
  </n-config-provider>
</template>

<script>
import { NThemeEditor, deDE, dateDeDE } from "naive-ui";
import theme from "@/assets/theme/naive-ui-theme-overrides.json";

export default {
  name: "App",
  components: {
    NThemeEditor,
  },
  setup() {
    /**
     * Use this for type hints under js file
     * @type import('naive-ui').GlobalThemeOverrides
     */
    const isProduction = process.env.NODE_ENV === "production";
    const themeOverrides = theme;
    return {
      themeOverrides,
      deDE,
      dateDeDE,
      isProduction,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_mdb_main";
</style>
