import config from "@/app.config";
import { LWLModel } from "@/models/base";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
} from "@/components/base/ModuleGenerator";
import { markRaw, createApp, h } from "vue";
import { NAvatar as CoreAvatar } from "naive-ui";
const Avatar = markRaw(CoreAvatar);

export class Provider extends LWLModel {
  static ENDPOINT = "provider";
  static VERBOSE_NAME = "Provider";
  static VERBOSE_NAME_PLURAL = "Provider";
  static DEFAULT_COLOR_CODE = "#E7E8E7";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.ROOT, param);
  }

  getColorCode() {
    return this.color ? this.color.web : Provider.DEFAULT_COLOR_CODE;
  }
}

export class ServiceProvider extends LWLModel {
  static ENDPOINT = "serviceprovider";
  static VERBOSE_NAME = "Service Provider";
  static VERBOSE_NAME_PLURAL = "Service Provider";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.ROOT, param);
  }

  get initials() {
    let initials = "";
    if (this.title && this.title.length > 1) {
      let words = this.title.split(" ");
      if (words.length > 1) {
        initials = words
          .map((word) => word.charAt(0).toUpperCase())
          .join("")
          .substring(0, 2);
      } else {
        initials = words[0].toUpperCase().substring(0, 1);
      }
    }
    return initials;
  }

  getRenderAvatar(size = 50, serviceProvider = this) {
    return h(
      Avatar,
      {
        size: size,
        round: true,
        "object-fit": "cover",
        style: `font-size: ${size * 0.48}px; text-transform: uppercase;`,
      },
      {
        default: () => {
          return serviceProvider.initials;
        },
      }
    );
  }

  getRenderedAvatar(size = 50, serviceProvider = this) {
    let Icon = createApp({
      render() {
        return serviceProvider.getRenderAvatar(size, serviceProvider);
      },
    });

    let instance = Icon.mount(document.createElement("div"));
    return instance.$el.outerHTML;
  }
}

export const ProviderStoreModule = new SimpleModuleGenerator({
  providers: Provider,
  serviceProviders: ServiceProvider,
}).module();
