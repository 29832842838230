import config from "@/app.config";
import store from "@/store/index.js";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
} from "@/components/base/ModuleGenerator";
import { LWLModel } from "@/models/base.js";

class RouteType extends LWLModel {
  static ENDPOINT = null;
  static VERBOSE_NAME = "Route";
  static VERBOSE_NAME_PLURAL = "Routen";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }
}

export default class Route extends RouteType {
  static ENDPOINT = "route";
}

export class ConnectedRoute extends RouteType {
  static ENDPOINT = "connectedroute";

  getNode1() {
    return store.getters["network/nodesById"](this.node1.id);
  }

  getNode2() {
    return store.getters["network/nodesById"](this.node2.id);
  }

  getNodes() {
    return [this.getNode1(), this.getNode2()];
  }

  getOtherNode(node) {
    let other = this.getNodes().find((x) => x.id != node.id);
    if (other) {
      return other;
    } else {
      console.error("ConnectedRoute.getOtherNode: No other node found.", this);
    }
  }
}

export const RouteStoreModule = new SimpleModuleGenerator({
  routes: Route,
  connectedRoutes: ConnectedRoute,
}).module();
