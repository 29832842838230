<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2460_45)">
      <circle cx="16.5" cy="12.5" r="12.5" :fill="backgroundColor" />
      <path
        d="M16.4929 21.5473L25.0402 21.5383L17.1909 29.3876C16.8004 29.7781 16.1672 29.7781 15.7767 29.3876L7.94555 21.5564L16.4929 21.5473Z"
        :fill="backgroundColor"
      />
      <circle cx="16.5" cy="12.5" r="9.5" :stroke="strokeColor" />
    </g>
    <defs>
      <filter
        id="filter0_d_2460_45"
        x="0"
        y="0"
        width="33"
        height="37.6804"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2460_45"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2460_45"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "WellZSNodeSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 33,
      height: 38,
    };
  },
};
</script>
