<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 47 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2753_794)">
      <path
        d="M6 3.5L6 31.5C6 33.433 7.51499 35 9.38382 35H37.6162C39.485 35 41 33.433 41 31.5V3.5C41 1.56701 39.485 0 37.6162 0H9.38382C7.51499 0 6 1.56701 6 3.5Z"
        :fill="backgroundColor"
      />
      <path
        d="M23.4999 32.8631L33.3366 32.8632L24.4326 41.7669C23.9175 42.2821 23.0823 42.2821 22.5672 41.7669L13.6633 32.8631L23.4999 32.8631Z"
        :fill="backgroundColor"
      />
    </g>

    <template v-if="technicalUsage === 'tr'">
      <path
        d="M12.7373 12.5127H22.3623"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5498 23.8877V12.5127"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.5127 23.8877V12.5127H29.4502C30.2624 12.5127 31.0414 12.8354 31.6157 13.4097C32.19 13.984 32.5127 14.763 32.5127 15.5752C32.5127 16.3874 32.19 17.1664 31.6157 17.7407C31.0414 18.315 30.2624 18.6377 29.4502 18.6377H25.5127"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.9502 23.8877L29.4502 18.6377"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'str'">
      <path
        d="M10.125 23.9499C11.051 24.5203 12.0738 24.8282 13.1145 24.8499C15.054 24.8499 16.875 24.0399 16.875 21.2499C16.875 18.9225 14.9617 18.0999 13.125 17.6499C11.625 17.2818 10.125 16.2999 10.125 14.0499C10.125 12.1212 11.7495 11.3499 13.1145 11.3499C14.1634 11.3582 15.1955 11.6667 16.125 12.2499"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5 11.5999H27.5"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 24.5999V11.5999"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 24.5999V11.5999H32.5C33.4283 11.5999 34.3185 11.9686 34.9749 12.625C35.6313 13.2814 36 14.1716 36 15.0999C36 16.0281 35.6313 16.9183 34.9749 17.5747C34.3185 18.2311 33.4283 18.5999 32.5 18.5999H30"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.5 24.5999L32.5 18.5999"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'sh'">
      <path
        d="M14 23.4749C15.0032 23.9898 16.1111 24.2678 17.2386 24.2874C19.3398 24.2874 21.3125 23.5561 21.3125 21.0374C21.3125 18.9362 19.2398 18.1936 17.25 17.7874C15.625 17.455 14 16.5686 14 14.5374C14 12.7962 15.7599 12.0999 17.2386 12.0999C18.3749 12.1073 19.493 12.3859 20.5 12.9124"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.7188 23.8811V12.5061"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.5938 23.8811V12.5061"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.7188 17.7561H32.5938"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>
    <template v-else-if="technicalUsage === 'sst'">
      <path
        d="M10.125 23.9499C11.051 24.5203 12.0738 24.8282 13.1145 24.8499C15.054 24.8499 16.875 24.0399 16.875 21.2499C16.875 18.9225 14.9617 18.0999 13.125 17.6499C11.625 17.2818 10.125 16.2999 10.125 14.0499C10.125 12.1212 11.7495 11.3499 13.1145 11.3499C14.1634 11.3582 15.1955 11.6667 16.125 12.2499"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 23.9499C20.426 24.5203 21.4488 24.8282 22.4895 24.8499C24.429 24.8499 26.25 24.0399 26.25 21.2499C26.25 18.9225 24.3367 18.0999 22.5 17.6499C21 17.2818 19.5 16.2999 19.5 14.0499C19.5 12.1212 21.1245 11.3499 22.4895 11.3499C23.5384 11.3582 24.5705 11.6667 25.5 12.2499"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.5 11.5999H37.5"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33 24.5999V11.5999"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>
    <template v-else-if="technicalUsage === 'uw'">
      <path
        d="M23.7656 12.4124L26.3906 23.7874L29.4531 12.4124L32.5156 23.7874L35.1406 12.4124"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2998 12.3999V19.9461C12.2998 21.0477 12.7374 22.1043 13.5164 22.8833C14.2954 23.6623 15.352 24.0999 16.4537 24.0999C17.5553 24.0999 18.6119 23.6623 19.3909 22.8833C20.1699 22.1043 20.6075 21.0477 20.6075 19.9461V12.3999"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>

    <template v-else-if="technicalUsage === 'mpr'">
      <path
        d="M10.2998 24.0628V12.1001H10.7599L14.3561 19.6347L17.9523 12.1001H18.4124V24.0628"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0205 24.0626V12.0999H24.6116C25.4657 12.0999 26.2849 12.4392 26.8889 13.0432C27.4929 13.6472 27.8322 14.4664 27.8322 15.3206C27.8322 16.1748 27.4929 16.994 26.8889 17.598C26.2849 18.202 25.4657 18.5413 24.6116 18.5413H22.0205"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.0283 24.0626V12.0999H33.6194C34.4735 12.0999 35.2927 12.4392 35.8967 13.0432C36.5007 13.6472 36.84 14.4664 36.84 15.3206C36.84 16.1748 36.5007 16.994 35.8967 17.598C35.2927 18.202 34.4735 18.5413 33.6194 18.5413H31.0283"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.2999 24.0628L33.6191 18.5415"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>
    <template v-else-if="technicalUsage === 'gss'">
      <path
        d="M20.9102 24.1409C21.8662 24.7299 22.9222 25.0477 23.9967 25.0701C25.9991 25.0701 27.8792 24.2338 27.8792 21.3532C27.8792 18.9503 25.9039 18.101 24.0075 17.6364C22.4588 17.2563 20.9102 16.2425 20.9102 13.9195C20.9102 11.9282 22.5874 11.1318 23.9967 11.1318C25.0796 11.1404 26.1452 11.459 27.1049 12.0611"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.5889 24.1412C31.5449 24.7301 32.6009 25.048 33.6754 25.0704C35.6778 25.0704 37.5579 24.2341 37.5579 21.3535C37.5579 18.9505 35.5826 18.1012 33.6862 17.6366C32.1376 17.2566 30.5889 16.2428 30.5889 13.9197C30.5889 11.9284 32.2661 11.1321 33.6754 11.1321C34.7583 11.1407 35.8239 11.4592 36.7836 12.0613"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.4748 11.6214C16.4621 11.1044 15.3366 10.8475 14.1998 10.8739C9.63119 10.8739 10.07 14.66 10.07 18.1012C10.07 21.5424 9.63119 25.3284 14.1998 25.3284C15.6243 25.4099 16.2644 25.3284 17.4748 24.6801V18.795H14.3774"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>
    <template v-else-if="technicalUsage === 'gra'">
      <path
        d="M20.043 24.5717V11.6306H22.5316C23.4556 11.6306 24.3418 11.9977 24.9952 12.6511C25.6486 13.3045 26.0157 14.1907 26.0157 15.1148C26.0157 16.0388 25.6486 16.925 24.9952 17.5784C24.3418 18.2318 23.4556 18.5989 22.5316 18.5989H20.043"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.514 24.5715L22.5322 18.5986"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2753 12.3013C15.3687 11.8383 14.3611 11.6083 13.3433 11.632C9.2531 11.632 9.64594 15.0217 9.64594 18.1026C9.64594 21.1835 9.2531 24.5731 13.3433 24.5731C14.6187 24.6461 15.1917 24.5731 16.2753 23.9926V18.7238H13.5023"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.7969 24.4848L32.7253 11.717H33.7075L37.6359 24.4848"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.3076 20.5564H36.1257"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>
    <template v-else>
      <path
        d="M20.333 11.6738V20.7943C20.333 21.8106 20.7434 22.7854 21.474 23.504C22.2045 24.2227 23.1954 24.6264 24.2285 24.6264C25.2617 24.6264 26.2525 24.2227 26.9831 23.504C27.7136 22.7854 28.124 21.8106 28.124 20.7943V11.6738"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.37695 11.6738H17.6549"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5156 24.3343V11.6738"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.3765 24.3343H31.5332V11.6738H37.3765"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.5332 17.5171H36.4036"
        :stroke="strokeColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </template>
    <defs>
      <filter
        id="filter0_d_2753_794"
        x="0.723742"
        y="0"
        width="45.5525"
        height="52.7058"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.27626" />
        <feGaussianBlur stdDeviation="2.63813" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2753_794"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2753_794"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";
export default {
  name: "TechnicalTransferPointNodeSVG",
  mixins: [SVGMixin],
  props: {
    technicalUsage: {
      type: String,
      required: false,
      default: "tue",
    },
  },
  data() {
    return {
      width: 33,
      height: 51,
    };
  },
};
</script>
