<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.18182 11.0909C1.18182 6.09091 6.09091 2 12.0909 2C18.0909 2 23 6.09091 23 11.0909C23 16.0909 18.0909 20.1818 12.0909 20.1818C11.1818 20.1818 10.2727 20.0909 9.36363 19.9091L4.27273 21.9091C4.18182 21.9091 4.09091 22 3.90909 22C3.72727 22 3.54545 21.9091 3.36363 21.8182C3.09091 21.6364 3 21.2727 3 21L3.45454 16.7273C2 15.0909 1.18182 13.0909 1.18182 11.0909Z"
      :fill="backgroundColor"
    />

    <path
      v-if="!isInterestNote"
      d="M14.2732 13.0907H7.72772V11.9998H14.2732V13.0907Z"
      :fill="strokeColor"
    />
    <path
      v-if="!isInterestNote"
      d="M17.5459 9.81796H7.72772V8.72705H17.5459V9.81796Z"
      :fill="strokeColor"
    />
    <path
      v-if="isInterestNote"
      d="M12.2591 7.16837L13.3387 9.45994L15.7527 9.82805C15.806 9.8361 15.8561 9.85965 15.8973 9.896C15.9385 9.93236 15.9692 9.98009 15.9859 10.0338C16.0025 10.0875 16.0045 10.145 15.9916 10.1998C15.9786 10.2546 15.9513 10.3045 15.9127 10.3438L14.1656 12.1262L14.578 14.6455C14.5871 14.7011 14.5812 14.7583 14.5608 14.8106C14.5405 14.8629 14.5067 14.9082 14.4631 14.9414C14.4195 14.9746 14.3679 14.9943 14.3142 14.9984C14.2605 15.0025 14.2067 14.9907 14.159 14.9645L12.0002 13.7768L9.841 14.9655C9.79326 14.9917 9.7395 15.0033 9.68579 14.9992C9.63207 14.995 9.58053 14.9752 9.53697 14.942C9.49341 14.9088 9.45957 14.8635 9.43926 14.8113C9.41894 14.759 9.41297 14.7018 9.422 14.6462L9.8344 12.1284L8.08727 10.346C8.04867 10.3066 8.02135 10.2567 8.00843 10.2019C7.9955 10.1471 7.99748 10.0896 8.01413 10.036C8.03079 9.98227 8.06146 9.93454 8.10267 9.89819C8.14388 9.86183 8.19398 9.83829 8.2473 9.83023L10.6613 9.4603L11.7409 7.16873C11.7647 7.11811 11.8017 7.07548 11.8474 7.04567C11.8932 7.01586 11.946 7.00004 11.9999 7C12.0538 6.99996 12.1066 7.01571 12.1524 7.04546C12.1982 7.0752 12.2352 7.11778 12.2591 7.16837Z"
      :fill="strokeColor"
    />
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";

export default {
  name: "NoteSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 33,
      height: 33,
    };
  },
  props: {
    isInterestNote: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#081D34",
    },
  },
};
</script>
