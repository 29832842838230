import { useToast } from "vue-toastification";
import auth from "@/services/auth.service";

export default class ModuleGenerator {
  /**
   * @param {Promis} listPromis
   * @param {Promis} detailPromis
   * @param {Object} lists
   * @param {Object} storeModule
   */
  constructor(promises, lists, storeModule) {
    this.LIST_OF_PROMISES = promises;
    this.lists = lists;
    this.storeModule = storeModule;
    this.toast = useToast();

    this.state = {};
    this.actions = {};
    this.mutations = {};
    this.getters = {};

    this.generateStoreModule();

    //overwrite if manually defined
    this.state = { ...this.state, ...this.storeModule.state };
    this.actions = { ...this.actions, ...this.storeModule.actions };
    this.mutations = { ...this.mutations, ...this.storeModule.mutations };
    this.getters = { ...this.getters, ...this.storeModule.getters };

    this.toast = useToast();
  }

  generateStoreModule() {
    this.createState();
    this.createActions();
    this.createMutations();
    this.createGetters();
  }

  createState() {
    // for each entry in object create a state
    for (let objectKey in this.lists) {
      // add state based on objectKey
      this.state[`${objectKey}`] = [];
      this.state[`${objectKey}Loading`] = false;
      this.state[`${objectKey}ListIsLoaded`] = false;
    }
  }

  createMutations() {
    // for each entry in object create a mutation
    for (let objectKey in this.lists) {
      // objectKey to camleCase
      let k = objectKey.charAt(0).toUpperCase() + objectKey.slice(1);
      // add mutation based on objectKey
      this.mutations[`fetchAll${k}`] = (state, payload) => {
        state[objectKey] = payload.objects;
        state[`${objectKey}ListIsLoaded`] = true;
        state[`${objectKey}Loading`] = false;
      };
      this.mutations[`set${k}`] = (state, payload) => {
        state[objectKey][payload.index] = payload.object;
        state[`${objectKey}Loading`] = false;
      };
      this.mutations[`set${k}Loading`] = (state, payload) => {
        state[`${objectKey}Loading`] = payload.status;
      };
      this.mutations[`set${k}ListIsLoaded`] = (state, payload) => {
        state[`${objectKey}ListIsLoaded`] = payload.status;
      };
      this.mutations[`add${k}`] = (state, payload) => {
        state[objectKey].push(payload.object);
        state[`${objectKey}Loading`] = false;
      };
      this.mutations[`append${k}`] = (state, payload) => {
        state[objectKey] = [...state[objectKey], ...payload.objects];
      };
      this.mutations[`remove${k}`] = (state, payload) => {
        // remove object from state based on index
        if (payload.index !== undefined) {
          state[objectKey].splice(payload.index, 1);
        } else {
          let index = state[objectKey].findIndex(
            (element) => element.id === payload.id
          );
          state[objectKey].splice(index, 1);
        }
      };
    }
  }

  createActions() {
    for (let objectKey in this.lists) {
      let k = objectKey.charAt(0).toUpperCase() + objectKey.slice(1);
      if (this.LIST_OF_PROMISES[objectKey]["list"] !== undefined) {
        this.actions[`fetchAll${k}`] = async (
          { commit, getters },
          id = null
        ) => {
          try {
            let listIsLoaded = getters[`${objectKey}ListIsLoaded`];
            if (!listIsLoaded) {
              commit(`set${k}Loading`, { status: true });
              console.log(`fetching all ${k}`);
              let response;
              let PROMISE_MODEL = this.LIST_OF_PROMISES[objectKey]["list"];
              if (id) {
                response = await PROMISE_MODEL(id);
              } else {
                response = await PROMISE_MODEL();
              }
              if (response) commit(`fetchAll${k}`, { objects: response });
            }
          } catch (error) {
            commit(`set${k}Loading`, { status: false });
            commit(`set${k}ListIsLoaded`, { status: false });
            if (error.response && error.response.data.detail) {
              this.toast.error(error.response.data.detail);
            } else {
              console.error(error);
            }
          }
        };
        this.actions[`fetchAll${k}Force`] = async (
          { commit, getters },
          id = null
        ) => {
          try {
            commit(`set${k}Loading`, { status: true });
            console.log(`Force fetching all ${k}`);
            let response;
            let PROMISE_MODEL = this.LIST_OF_PROMISES[objectKey]["list"];
            if (id) {
              response = await PROMISE_MODEL(id);
            } else {
              response = await PROMISE_MODEL();
            }
            if (response) commit(`fetchAll${k}`, { objects: response });
          } catch (error) {
            commit(`set${k}Loading`, { status: false });
            commit(`set${k}ListIsLoaded`, { status: false });
            if (error.response.data.detail) {
              this.toast.error(error.response.data.detail);
            } else {
              console.error(error);
            }
          }
        };
      }
      if (this.LIST_OF_PROMISES[objectKey]["details"] !== undefined) {
        this.actions[`fetch${k}Details`] = async (
          { commit, getters },
          param
        ) => {
          try {
            let nodes = getters[objectKey];
            let IsLoading = getters[`${objectKey}IsLoading`];
            // console.log("fetching details for " + objectKey, nodes);
            if (!IsLoading) {
              let node = nodes.find((element) => element.id === param.id);

              let PROMISE_MODEL = this.LIST_OF_PROMISES[objectKey]["details"];

              let fetchDetails = async () => {
                commit(`set${k}Loading`, { status: true });
                let response = await PROMISE_MODEL(param);
                if (response) {
                  response._has_details = true;
                  let index = nodes.indexOf(node);
                  if (node && index) {
                    Object.assign(node, response);
                    commit(`set${k}`, { object: response, index: index });
                  } else {
                    commit(`add${k}`, { object: response });
                  }
                }
                return response;
              };

              if (node === undefined) {
                return fetchDetails();
              } else if (node._has_details === undefined) {
                return fetchDetails();
              } else {
                return node;
              }
            }
          } catch (error) {
            commit(`set${k}Loading`, { status: false });
            if (error.response && error.response.data.detail) {
              this.toast.error(error.response.data.detail);
            } else {
              console.error(error);
            }
          }
        };
      }
    }
  }
  createGetters() {
    for (let objectKey in this.lists) {
      this.getters[`${objectKey}`] = function (state) {
        return state[objectKey];
      };
      this.getters[`${objectKey}ById`] = (state) => (id) => {
        const item = state[objectKey].find((element) => element.id === id);
        if (!item) {
          console.info(`Cannot fetch from state: ${objectKey} with ID ${id}`);
        }
        return item;
      };

      this.getters[`${objectKey}ByIds`] = (state) => (ids) => {
        return state[objectKey].filter((element) => ids.includes(element.id));
      };
      this.getters[`${objectKey}IsLoading`] = function (state) {
        return state[`${objectKey}Loading`];
      };
      this.getters[`${objectKey}ListIsLoaded`] = function (state) {
        return state[`${objectKey}ListIsLoaded`];
      };
    }
  }

  /**
   *
   * @returns {Object} Vuex store module
   */
  module() {
    let data = {
      namespaced: true,
      state: this.state,
      mutations: this.mutations,
      actions: this.actions,
      getters: this.getters,
    };
    console.log(data);
    return data;
  }
}

export class SimpleModuleGenerator {
  constructor(models, overrides, hooks) {
    this.toast = useToast();
    this._module = {
      namespaced: true,
      state: {},
      getters: {},
      mutations: {},
      actions: {},
    };

    this.models = models;
    this.hooks = hooks || {};

    for (const [name, Model] of Object.entries(models)) {
      let cname = name.charAt(0).toUpperCase() + name.slice(1);
      let sname = cname.replace(/s$/, "");
      let vname = Model.VERBOSE_NAME_PLURAL;

      this.addState(name, []);
      this.addState(`${name}Index`, new Map());
      this.addState(`fetchedAll${cname}`, false);
      this.addState(`fetchingAll${cname}`, false);
      this.addState(`${sname}ToastId`, null);
      this.addGetter(`${name}Index`, (state) => state[`${name}Index`]);
      this.addGetter(`${name}IndexById`, (state) => (id) => {
        return state[`${name}Index`].get(Number(id));
      });

      this.addGetter(name, (state) => state[name]);
      this.addGetter(`${name}ById`, (state, getters) => (id) => {
        let index = getters[`${name}IndexById`](id);
        let item;
        if (index === undefined) {
          item = Model.fromEmpty();
        } else {
          item = state[name][index];
        }
        return item;
      });
      this.addGetter(`${name}ByIds`, (state, getters) => (ids) => {
        return ids.reduce((acc, id) => {
          let index = getters[`${name}IndexById`](id);
          if (index !== undefined) {
            acc.push(state[name][index]);
          }
          return acc;
        }, []);
      });

      this.addGetter(
        `fetchedAll${cname}`,
        (state) => state[`fetchedAll${cname}`]
      );
      this.addGetter(
        `fetchingAll${cname}`,
        (state) => state[`fetchingAll${cname}`]
      );
      this.addGetter(`${sname}ToastId`, (state) => state[`${sname}ToastId`]);

      this.addMutation(
        `setAll${cname}`,
        (state, payload) => (state[name] = payload)
      );
      this.addMutation(
        `setFetchedAll${cname}`,
        (state, payload) => (state[`fetchedAll${cname}`] = payload)
      );
      this.addMutation(
        `set${cname}ToastId`,
        (state, payload) => (state[`${sname}ToastId`] = payload)
      );

      this.addMutation(`setFetchingAll${cname}`, (state, payload) => {
        state[`fetchingAll${cname}`] = payload;
      });

      this.addMutation(`add${cname}`, (state, payload) => {
        let item = payload.object || payload; //backwards compatibility
        
        if (this.hooks[name] && this.hooks[name].preAdd) {
          this.hooks[name].preAdd(state, item);
        }

        let index = state[`${name}Index`].get(item.id);
        if (index === undefined) {
          let len = state[name].push(item);
          state[`${name}Index`].set(item.id, len - 1);
        } else {
          Object.assign(state[name][index], item);
        }

        if (this.hooks[name] && this.hooks[name].postAdd) {
          this.hooks[name].postAdd(state, item);
        }
      });

      this.addMutation(`remove${sname}`, (state, payload) => {
        let item = payload.object || payload; //backwards compatibility
        
        if (this.hooks[name] && this.hooks[name].preRemove) {
          this.hooks[name].preRemove(state, item);
        }
        
        let index = state[`${name}Index`].get(item.id);
        if (index !== undefined) {
          state[name].splice(index, 1);
          state[`${name}Index`].delete(item.id);
        }

        // reindex
        state[`${name}Index`] = new Map();
        state[name].forEach((item, i) => {
          state[`${name}Index`].set(item.id, i);
        });
      });

      this.addAction(
        `fetchAll${cname}`,
        async ({ commit, dispatch, getters }, param) => {
          let fetchedAll = getters[`fetchedAll${cname}`];
          let fetchingAll = getters[`fetchingAll${cname}`];
          if (fetchedAll || fetchingAll) {
            return;
          }

          commit(`setFetchingAll${cname}`, true);
          let toastId = getters[`${sname}ToastId`];
          if (toastId === null) {
            toastId = this.toast.info(`Lade ${vname} ...`, {
              timeout: false,
            });
            commit(`set${cname}ToastId`, toastId);
          }

          let { result, next } = await Model.fetchAll(param);
          result.forEach((item) => {
            commit(`add${cname}`, item);
          });

          commit(`setFetchingAll${cname}`, false);
          //paging support
          if (next) {
            dispatch(`fetchAll${cname}`, { next });
          } else {
            commit(`setFetchedAll${cname}`, true);
            let toastId = getters[`${sname}ToastId`];
            if (toastId !== null) {
              this.toast.dismiss(toastId);
              commit(`set${cname}ToastId`, null);
            }
          }
        }
      );
      this.addAction(
        `fetch${cname}ByIds`,
        async ({ commit, dispatch, getters }, { projectId, ids, force }) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }

          let missingIds;
          if (force === true) {
            missingIds = ids;
          } else {
            missingIds = ids.filter((id) => {
              let item = getters[`${name}ById`](id);
              console.log(item);
              return item === null || !item.loaded;
            });
          }
          if (missingIds.length === 0) {
            console.info(`Don't refetch, all ${cname} Loaded`);
            return;
          }

          let toastId = getters[`${sname}ToastId`];
          if (toastId === null) {
            toastId = this.toast.info(`Lade ${vname}...`, { timeout: false });
            commit(`set${cname}ToastId`, toastId);
            // console.log(`open toast ${toastId} for ${cname}`)
          }

          // console.log(`fetching ${cname}`, missingIds);
          let { result, next } = await Model.fetchByIds(projectId, missingIds);
          result.forEach((item) => {
            commit(`add${cname}`, item);
          });

          if (toastId) {
            this.toast.dismiss(toastId);
            commit(`set${cname}ToastId`, null);
          }
        }
      );
      this.addAction(
        `patch${sname}Details`,
        async ({ commit, getters }, payload) => {
          let result = await Model.patchDetails(payload);
          let item = Model.fromAPI(result);
          commit(`add${cname}`, item);
          return result;
        }
      );
      this.addAction(`delete${sname}`, async ({ commit, getters }, payload) => {
        let result = await Model.delete(payload);
        commit(`remove${sname}`, payload);
      });

      if (this.hooks[name] && this.hooks[name].init) {
        this.hooks[name].init(this);
      }
    }

    this.addAction("resetState", async ({ commit }) => {
      commit("reset");
    });
    this.addMutation("reset", (state, payload) => {
      this.reset(state);
    });

    if (overrides) {
      //apply overrides
      this._module.state = { ...this._module.state, ...overrides.state };
      this._module.actions = { ...this._module.actions, ...overrides.actions };
      this._module.mutations = {
        ...this._module.mutations,
        ...overrides.mutations,
      };
      this._module.getters = { ...this._module.getters, ...overrides.getters };
    }
  }

  reset(state) {
    console.warn("resetState", state);
    for (const [name, Model] of Object.entries(this.models)) {
      let cname = name.charAt(0).toUpperCase() + name.slice(1);
      let sname = cname.replace(/s$/, "");
      state[name] = [];
      state[`${name}Index`] = new Map();
      state[`fetchedAll${cname}`] = Array.isArray(state[`fetchedAll${cname}`])
        ? []
        : false;
      state[`fetchingAll${cname}`] = Array.isArray(state[`fetchingAll${cname}`])
        ? []
        : false;
    }
    return state;
  }

  module() {
    console.log("SimpleModuleGenerator", this._module);
    return this._module;
  }

  addState(name, value) {
    this._module.state[name] = value;
    return this;
  }

  addGetter(name, value) {
    this._module.getters[name] = value;
    return this;
  }

  addMutation(name, value) {
    this._module.mutations[name] = value;
    return this;
  }

  addAction(name, value) {
    this._module.actions[name] = value;
    return this;
  }
}

export const fetchAllWithPaging = (model, api, param) => {
  let url;
  let withPaging = typeof param === "object";
  if (withPaging) {
    url = param.next || `${api}${param.id}/${model.ENDPOINT}/`;
  } else if (param) {
    url = `${api}${param}/${model.ENDPOINT}/`;
  } else {
    url = `${api}${model.ENDPOINT}/`;
  }
  return auth
    .get(url)
    .then((response) => {
      const items = response.data.results.map((result) => {
        return model.from_api(result);
      });
      return {
        result: items,
        next: response.data.next,
      };
    })
    .catch((error) => {
      let status = error.toJSON().status;
      if (status === 403) {
        console.info("Missing Permissions: " + url);
        return {
          result: [],
          next: null,
        };
      } else {
        throw error;
      }
    });
};

export const fetchByIds = (model, api, projectId, ids) => {
  let base = `${api}${projectId}/${model.ENDPOINT}/`;
  let params = ids.join("&id=");
  let url = `${base}?id=${params}`;
  return fetchAllWithPaging(model, api, { next: url });
};
