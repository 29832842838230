<template>
  <svg
    :width="scaledWidth"
    :height="scaledHeight"
    viewBox="0 0 33 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2469_5)">
      <path
        d="M26.5 0H6.5C5.11929 0 4 0.962625 4 2.15008V35.6707C4 36.8582 5.11929 37.8208 6.5 37.8208H26.5C27.8807 37.8208 29 36.8582 29 35.6707V2.15008C29 0.962625 27.8807 0 26.5 0Z"
        :fill="backgroundColor"
      />
      <path
        d="M21.709 11.1802L21.709 16.7704"
        :stroke="strokeColor"
        stroke-linecap="round"
      />
      <path
        d="M16.7773 37.3429H23.3033L17.5227 42.5133C17.111 42.8815 16.4436 42.8815 16.032 42.5133L10.2513 37.3429H16.7773Z"
        :fill="backgroundColor"
      />
      <path
        d="M8.16536 28.2715H24.832C25.7604 28.2715 26.4154 28.9329 26.4154 29.6349V34.094C26.4154 34.796 25.7604 35.4574 24.832 35.4574H8.16536C7.237 35.4574 6.58203 34.796 6.58203 34.094V29.6349C6.58203 28.9329 7.237 28.2715 8.16536 28.2715Z"
        :stroke="strokeColor"
      />
      <path
        d="M8.16536 2.36328H24.832C25.7604 2.36328 26.4154 3.02469 26.4154 3.72669V24.2241C26.4154 24.9261 25.7604 25.5876 24.832 25.5876H8.16536C7.237 25.5876 6.58203 24.9261 6.58203 24.2241V3.72669C6.58203 3.02469 7.237 2.36328 8.16536 2.36328Z"
        :stroke="strokeColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2469_5"
        x="0"
        y="0"
        width="33"
        height="50.7896"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2469_5"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2469_5"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
import SVGMixin from "@/components/map/markers/materials/SVG/SVGMixin";
export default {
  name: "DistributorNodeSVG",
  mixins: [SVGMixin],
  data() {
    return {
      width: 33,
      height: 51,
    };
  },
};
</script>
