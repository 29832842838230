import { useToast } from "vue-toastification";
const toast = useToast();

export const showErrorToast = (error) => {
  let reason = "";
  if (error.response && error.response.data.error) {
    let user_info = error.response.data.error.user_info;
    if (user_info && Object.keys(user_info).length) {
      reason = user_info.title + "\n" + user_info.description;
    } else {
      reason = error.response.data.detail;
    }
  }

  if (String(error.response?.data?.code).startsWith("4")) {
    reason = "Warnung" + "\n" + reason;
    toast.warning(reason, {
      timeout: 8000,
    });
  } else {
    reason = "Systemfehler" + "\n" + reason;
    toast.error(reason, {
      timeout: false,
    });
  }
};

export const showSuccessToast = (message) => {
  let reason = "Erfolgreich";
  if (message) reason = message;
  toast.success(reason);
};

export const showInfoToast = (message) => {
  let reason = "Information";
  if (message) reason = message;
  toast.info(reason);
};
