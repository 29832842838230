import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store/index.js";
import AuthService from "@/services/auth.service";

const routes = [
  {
    path: "/",
    name: "Base",
    component: () => import("@/views/Base.vue"),
    props: true,
    children: [
      {
        path: "projekt",
        name: "ProjectsIndex",
        component: () => import("@/views/project/ProjectsIndex.vue"),
        props: true,
      },
      {
        path: "projekt/:slug/",
        name: "ProjectDetail",
        component: () => import("@/views/project/ProjectDetail.vue"),
        props: true,
        children: [
          {
            path: "geo",
            component: () => import("@/views/project/ProjectGeo.vue"),
            name: "ProjectGeo",
            children: [
              {
                path: "karte",
                component: () => import("@/views/project/geo/ProjectMap.vue"),
                name: "ProjectMap",
                meta: {
                  permissionRequired: "projects.view_map",
                },
              },
              {
                path: "schema",
                component: () =>
                  import("@/views/project/geo/ProjectSchema.vue"),
                name: "ProjectSchema",
                meta: {
                  permissionRequired: "projects.view_schema",
                },
              },
            ],
          },
          {
            path: "uebersicht",
            component: () => import("@/views/project/ProjectDashboard.vue"),
            name: "ProjectDashboard",
          },
          {
            path: "auftraege",
            component: () => import("@/views/project/ProjectJobs.vue"),
            name: "ProjectJobs",
          },
          {
            path: "pool",
            component: () => import("@/views/project/ProjectPool.vue"),
            name: "ProjectPool",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.getUrlRoute = function (name, params) {
  return this.resolve({ name: name, params: params }).href;
};

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = AuthService.isLoggedIn();

  if (authRequired && !loggedIn) {
    return next("/login");
  } else if (loggedIn && to.path == "/login") {
    return next("/");
  }

  // TODO: timing problems: if we check for permissions here at startup, the whoami is not yet loaded
  // if (to.meta.permissionRequired) {
  //   let required = to.meta.permissionRequired;
  //   let ci = setInterval(() => {
  //     if (store.getters["auth/hasPermissionsLoaded"]) {
  //       if (!store.getters["auth/hasPermission"](required)) {
  //         clearInterval(ci);
  //         return next("/");
  //       } else {
  //         return next();
  //       }
  //     }
  //   }, 100)
  // } else {
  //   return next();
  // }
  return next();
});

export default router;
