import { LWLModel } from "./base.js";
import config from "@/app.config";
import auth from "@/services/auth.service";
import ModuleGenerator from "@/components/base/ModuleGenerator";

export class AddressPoint extends LWLModel {
  static ENDPOINT = "addresspoint";
  static VERBOSE_NAME = "Adress-Punkt";
  static VERBOSE_NAME_PLURAL = "Adress-Punkte";

  static fetchAll(project_id) {
    console.error(
      "NotImplemented: fetchAll for AddressPoints not implemented yet."
    );
  }

  static fetchDetails({ id }) {
    return auth
      .get(`${config.endpoints.CADASTRAL}addresspoint/${id}/`)
      .then((response) => {
        return AddressPoint.from_api(response.data);
      });
  }

  getMeta() {
    return this.meta.map((meta) => AddressMeta.from_api(meta));
  }
}

export class AddressMeta extends LWLModel {}

export const CadastralStoreModule = new ModuleGenerator(
  {
    addressPoints: {
      list: AddressPoint.fetchAll,
      details: AddressPoint.fetchDetails,
    },
  },
  {
    addressPoints: [],
  },
  {
    state: {
      currentAddressPoint: null,
    },
    mutations: {
      setCurrentAddressPoint(state, addressPoint) {
        state.currentAddressPoint = addressPoint;
      },
    },
    getters: {
      currentAddressPoint(state) {
        return state.currentAddressPoint;
      },
    },
    actions: {
      setCurrentAddressPoint({ commit, dispatch }, payload) {
        if (payload === null) {
          commit("setCurrentAddressPoint", null);
        } else {
          dispatch("fetchAddressPointsDetails", payload).then(
            (addressPoint) => {
              commit("setCurrentAddressPoint", addressPoint);
            }
          );
        }
      },
    },
  }
).module();
