import config from "@/app.config";
import auth from "@/services/auth.service";
import { useToast } from "vue-toastification";

export const DashboardStoreModule = {
  namespaced: true,
  state() {
    return {
      fetchedAllStats: false,
      fetchingAllStats: false,
      stats: {},
    };
  },
  mutations: {
    setStats(state, payload) {
      state.stats = payload;
    },
    setResetStats(state) {
      state.stats = {};
      state.fetchedAllStats = false;
    },
    setfetchingAllStats(state, payload) {
      state.fetchingAllStats = payload;
    },
    setfetchedAllStats(state, payload) {
      state.fetchedAllStats = payload;
    },
  },
  actions: {
    fetchStats: ({ commit, getters }, payload) => {
      if (
        getters.fetchingAllStats ||
        payload === null
      ) {
        return;
      }

      commit("setfetchingAllStats", true);
      let toast = useToast();
      let toastId = toast.info(`Lade Statistik Daten ...`, {
        timeout: false,
      });

      auth
        .get(`${config.endpoints.PROJECTS}${payload}/stats/`)
        .then(async (response) => {
          if (Object.keys(response.data).length !== 0) {
            commit("setStats", response.data);
            commit("setfetchedAllStats", true);
            commit("setfetchingAllStats", false);
            toast.dismiss(toastId);
          }
        })
        .catch((error) => {
          console.error(error);
          commit("setfetchingAllStats", false);
          toast.dismiss(toastId);
        });
    },
    resetStats: ({ commit }) => {
      commit("setResetStats");
    },
  },
  getters: {
    fetchingAllStats: (state) => state.fetchingAllStats,
    fetchedAllStats: (state) => state.fetchedAllStats,
    stats: (state) => state.stats,

    pipeStats: (state) => state.stats["pipes"],
    cableStats: (state) => state.stats["cables"],
    spliceTrayStats: (state) => state.stats["splice_trays"],
    wellStats: (state) => state.stats["wells"],
    houseBoxStats: (state) => state.stats["house_boxes"],
    distributorStats: (state) => state.stats["distributors"],
    centerStats: (state) => state.stats["centers"],
    serverCabinetStats: (state) => state.stats["server_cabinets"],
    patchFieldStats: (state) => state.stats["patch_fields"],
    spliceStats: (state) => state.stats["splices"],
    vNodeStats: (state) => state.stats["v_nodes"],
    interestNoteStats: (state) => state.stats["interest_notes"],
  },
};
